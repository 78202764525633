"use client";

import { EditListNameButton } from "@/app/(app)/dashboard/list/components/edit-list-name-button";
import { WorkflowControlIcon } from "@/app/(app)/dashboard/list/components/workflow-control-icon";
import { type GetListInfoResponse } from "@/app/api/list/info/[slug]/route";
import { FeedbackWidgetButton } from "@/components/functional/feedback-widget-button";
import { ProfileSettings } from "@/components/functional/profile-settings";
import { ReturnIcon } from "@/components/icons/return-icon";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Skeleton } from "@/components/ui/skeleton";
import { SubscriptionContext } from "@/hooks/subscription-context";
import { useSafeContext } from "@/hooks/useSafeContext";
import { cn, formatDate } from "@/lib/utils";
import { type List, type Subscriptions } from "@prisma/client";
import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";
import { CreditBadge } from "../credit-badge";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface HeaderProps extends React.HTMLAttributes<HTMLDivElement> {}

function InlineListHeader({
  list,
  isListStats,
  showBackToLists,
  setListName,
}: {
  list: List;
  isListStats: boolean;
  showBackToLists: boolean;
  setListName?: (name: string) => void;
}) {
  const subscriptionContext = useSafeContext(SubscriptionContext);

  const isProcessing =
    !isListStats &&
    (list.isProcessing ||
      list.isProcessingICP ||
      list.isProcessingEmails ||
      list.isProcessingAccountEnrichment ||
      list.isProcessingContactEnrichment ||
      list.isProcessingUpload ||
      list.isProcessingNewAccounts);

  return (
    <>
      <div className="flex flex-row gap-lg items-center">
        <Label className="text-base flex flex-row gap-sm items-center">
          {showBackToLists && (
            <Link href={"/dashboard/lists"} className="mr-sm">
              <Button
                variant={"outline"}
                className="flex flex-row gap-sm items-center"
              >
                <ReturnIcon />
                <span>Lists</span>
              </Button>
            </Link>
          )}
          {"   "}
          {isListStats ? (
            <Link
              className="hover:underline font-[525]"
              href={`/dashboard/list/${list.id}`}
            >
              {list.name}
            </Link>
          ) : (
            <span className="font-[525]">{list.name}</span>
          )}
          {isProcessing ? "(Processing Updates)" : ""}
          {isListStats ? " / Fetch Stats" : ""}
        </Label>
        {!subscriptionContext.subscription.simpleUI && (
          <WorkflowControlIcon list={list} />
        )}
        <EditListNameButton list={list} setListName={setListName} />
      </div>
      <div className="flex flex-row gap-xl items-center">
        <h3 className="text-sm text-text-secondary tracking-tight">
          Created {formatDate(list.createdAt)} by{" "}
          <strong>{list.createdBy ?? "Unknown"}</strong>
        </h3>
      </div>
    </>
  );
}

export function ListHeader({
  className,
  hideSideBar,
  subscription,
  alwaysHideSidebar,
  setHideSideBar,
}: HeaderProps & {
  subscription: Subscriptions;
  alwaysHideSidebar: boolean;
  hideSideBar: boolean;
  setHideSideBar: (hideSideBar: boolean) => void;
}) {
  const path = usePathname();
  const [list, setList] = useState<List | null>(null);
  const [loadingList, setLoadingList] = useState(true);
  const isListStats = path != null && path.includes("list-stats");

  useEffect(() => {
    if (path) {
      void fetch(`/api/list/info/${path.split("/").pop()}`).then((res) => {
        if (res.ok) {
          void res.json().then((data: GetListInfoResponse) => {
            setList(data.list);
            setLoadingList(false);
          });
        }
      });
    }
  }, [path]);

  return (
    <div
      className="py"
      style={{
        maxWidth:
          hideSideBar || alwaysHideSidebar ? "100vw" : "calc(100vw - 180px)",
      }}
    >
      <Badge
        variant={"outline"}
        className={cn(
          "pl-lg w-full h-12 border-r rounded-none flex bg-gray-100 justify-start items-center font-medium text-sm",
          className
        )}
      >
        {alwaysHideSidebar ? (
          <></>
        ) : hideSideBar ? (
          <ChevronRightIcon
            className="cursor-pointer"
            onClick={() => setHideSideBar(false)}
          />
        ) : (
          <ChevronLeftIcon
            className="cursor-pointer"
            onClick={() => setHideSideBar(true)}
          />
        )}

        <div className={cn(alwaysHideSidebar ? "px-0" : "px-2xl")}>
          <div id="list-title-and-description" className="flex flex-row gap-xl">
            {loadingList ? (
              <Skeleton className="w-[200px]" />
            ) : list ? (
              <InlineListHeader
                showBackToLists={alwaysHideSidebar}
                list={list}
                isListStats={isListStats}
              />
            ) : (
              <Label className="text-base">List</Label>
            )}
          </div>
        </div>

        <div className={"ml-auto flex flex-row gap-lg items-center"}>
          <CreditBadge subscription={subscription} />
          <FeedbackWidgetButton />
          <ProfileSettings hidePersonal={false} />
        </div>
      </Badge>
    </div>
  );
}

export function EditableListHeader({
  className,
  hideSideBar,
  subscription,
  alwaysHideSidebar,
  list,
  setListName,
  setHideSideBar,
}: HeaderProps & {
  subscription: Subscriptions;
  alwaysHideSidebar: boolean;
  hideSideBar: boolean;
  list: List;
  setListName: (name: string) => void;
  setHideSideBar: (hideSideBar: boolean) => void;
}) {
  const path = usePathname();
  const isListStats = path != null && path.includes("list-stats");

  return (
    <div
      className="py"
      style={{
        maxWidth:
          hideSideBar || alwaysHideSidebar ? "100vw" : "calc(100vw - 180px)",
      }}
    >
      <Badge
        variant={"outline"}
        className={cn(
          "pl-lg w-full h-12 border-r rounded-none flex bg-gray-100 justify-start items-center font-medium text-sm",
          className
        )}
      >
        {alwaysHideSidebar ? (
          <></>
        ) : hideSideBar ? (
          <ChevronRightIcon
            className="cursor-pointer"
            onClick={() => setHideSideBar(false)}
          />
        ) : (
          <ChevronLeftIcon
            className="cursor-pointer"
            onClick={() => setHideSideBar(true)}
          />
        )}

        <div className={cn(alwaysHideSidebar ? "px-0" : "px-2xl")}>
          <div id="list-title-and-description" className="flex flex-row gap-xl">
            <InlineListHeader
              showBackToLists={alwaysHideSidebar}
              list={list}
              isListStats={isListStats}
              setListName={setListName}
            />
          </div>
        </div>

        <div className={"ml-auto flex flex-row gap-lg items-center"}>
          <CreditBadge subscription={subscription} />
          <FeedbackWidgetButton />
          <ProfileSettings hidePersonal={false} />
        </div>
      </Badge>
    </div>
  );
}

export function ListBuilderHeader({
  className,
  hideSideBar,
  subscription,
  alwaysHideSidebar,
  setHideSideBar,
}: HeaderProps & {
  subscription: Subscriptions;
  alwaysHideSidebar: boolean;
  hideSideBar: boolean;
  setHideSideBar: (hideSideBar: boolean) => void;
}) {
  const path = usePathname();
  const [list, setList] = useState<List | null>(null);
  const [loadingList, setLoadingList] = useState(true);

  useEffect(() => {
    if (path) {
      void fetch(`/api/list/info/${path.split("/").pop()}`).then((res) => {
        if (res.ok) {
          void res.json().then((data: GetListInfoResponse) => {
            setList(data.list);
          });
        }
        setLoadingList(false);
      });
    } else {
      setLoadingList(false);
    }
  }, [path]);

  return (
    <div
      className="py"
      style={{
        maxWidth:
          hideSideBar || alwaysHideSidebar ? "100vw" : "calc(100vw - 180px)",
      }}
    >
      <Badge
        variant={"outline"}
        className={cn(
          "pl-lg w-full h-12 border-r rounded-none flex bg-gray-100 justify-start items-center font-medium text-sm",
          className
        )}
      >
        {alwaysHideSidebar ? (
          list ? (
            <Link href={"/dashboard/list/" + list.id}>
              <Button
                variant={"outline"}
                className="flex flex-row gap-sm items-center"
              >
                <ReturnIcon />
                <span>Back to List</span>
              </Button>
            </Link>
          ) : (
            <Link href={"/dashboard/lists"}>
              <Button
                variant={"outline"}
                className="flex flex-row gap-sm items-center"
              >
                <ReturnIcon />
                <span>Back to Lists</span>
              </Button>
            </Link>
          )
        ) : hideSideBar ? (
          <ChevronRightIcon
            className="cursor-pointer"
            onClick={() => setHideSideBar(false)}
          />
        ) : (
          <ChevronLeftIcon
            className="cursor-pointer"
            onClick={() => setHideSideBar(true)}
          />
        )}

        <div className={cn(alwaysHideSidebar ? "px-xl" : "px-2xl")}>
          <div id="list-title-and-description" className="flex flex-row gap-xl">
            {loadingList ? (
              <Skeleton className="w-[200px] h-[25px]" />
            ) : list != null ? (
              <Label className="text-base">
                Search Accounts To Add -{" "}
                {list?.name ? (
                  <Link
                    href={`/dashboard/list/${list.id}`}
                    className="hover:underline"
                  >
                    {list.name}
                  </Link>
                ) : (
                  "New List"
                )}
              </Label>
            ) : (
              <Label className="text-base">Search Accounts To Add</Label>
            )}
          </div>
        </div>

        <div className={"ml-auto flex flex-row gap-lg items-center"}>
          <CreditBadge subscription={subscription} />
          <FeedbackWidgetButton />
          <ProfileSettings hidePersonal={false} />
        </div>
      </Badge>
    </div>
  );
}
