"use client";

import { Separator } from "@/components/ui/separator";
import { Label } from "@/components/ui/label";
import {
  CompactDialogBody,
  CompactDialogContent,
  CompactDialogHeader,
  Dialog,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { PlanType, type Subscriptions } from "@prisma/client";
import React, { useState } from "react";
import { Button } from "@/components/ui/button";
import { NavBlockerLink } from "@/components/functional/nav-blocker-link";
import { useRouter } from "next/navigation";
import { ManageSubscriptionButton } from "@/components/functional/upgrade-subscription-button";
import { addOrdinalSuffix } from "@/lib/utils";

const creditCosts = {
  "AI Research / Enrichments": 1,
  "Contact Fetch (Per Contact)": 1,
  "Email/Sequence Generation": 1,
  "Email Enrichment": 2,
  "Mobile Phone Enrichment": 10,
};

export function CreditsInfoDialog({
  subscription,
  showUsageLog,
  children,
}: {
  subscription: Subscriptions;
  showUsageLog: boolean;
  children: React.ReactNode;
}) {
  return (
    <Dialog>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <CompactDialogContent>
        <CompactDialogHeader>
          <DialogTitle>
            LavaReach Credits Remaining:{" "}
            {subscription.maxCredits -
              subscription.creditsUsed +
              subscription.oneTimeCredits}
          </DialogTitle>
        </CompactDialogHeader>
        <Separator />
        <CompactDialogBody>
          <div className="flex flex-col w-full gap-lg">
            {subscription.maxCredits > 0 &&
              subscription.planType === PlanType.Monthly && (
                <Label>
                  {subscription.maxCredits} Credits will be added on the{" "}
                  {addOrdinalSuffix(subscription.renewDate)} day of every month
                </Label>
              )}
            {subscription.planType === PlanType.NotPaid && (
              <Label>No paid subscription.</Label>
            )}

            <Label>Credit Cost Breakdown:</Label>
            <div className="grid grid-cols-2">
              {Object.entries(creditCosts).map(([key, value]) => (
                <React.Fragment key={key}>
                  <Label key={key}>{key} </Label>
                  <Label key={key}>{value} Credits</Label>
                  <Separator key={key} className="col-span-2" />
                </React.Fragment>
              ))}
            </div>
            {showUsageLog && (
              <Button variant={"outline"} size={"xs"}>
                <NavBlockerLink href={"/dashboard/credits-info"}>
                  Credit Usage Log
                </NavBlockerLink>
              </Button>
            )}
          </div>
        </CompactDialogBody>
      </CompactDialogContent>
    </Dialog>
  );
}

export function SimpleCreditsInfoDialog({
  subscription,
  children,
}: {
  subscription: Subscriptions;
  children: React.ReactNode;
}) {
  const [open, setOpen] = useState(false);
  const router = useRouter();

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <CompactDialogContent>
        <CompactDialogHeader>
          <DialogTitle>
            LavaReach Credits Remaining:{" "}
            {subscription.maxCredits -
              subscription.creditsUsed +
              subscription.oneTimeCredits}
          </DialogTitle>
          {subscription.maxCredits > 0 &&
            subscription.planType === PlanType.Monthly && (
              <Label>
                {subscription.maxCredits} Credits will be added on the{" "}
                {addOrdinalSuffix(subscription.renewDate)} day of every month
              </Label>
            )}
          {subscription.planType === PlanType.NotPaid && (
            <Label>No paid subscription.</Label>
          )}
        </CompactDialogHeader>
        <Separator />
        <CompactDialogBody>
          <div className="flex flex-col w-full gap-lg">
            {subscription.stripeSubscriptionId != null ||
            subscription.planType === PlanType.Monthly ? (
              <Button
                variant={"outline"}
                onMouseDown={() => {
                  router.push("/dashboard/settings/billing");
                  setOpen(false);
                }}
              >
                Manage Subscription
              </Button>
            ) : (
              <ManageSubscriptionButton
                variant={"outline"}
                subscription={subscription}
              />
            )}
          </div>
        </CompactDialogBody>
      </CompactDialogContent>
    </Dialog>
  );
}
